import SlideUpOnScroll from "../../SlideUpOnScroll";

export default function Service({ img, title, desc }) {
  return (
    <div className="flex flex-col justify-start items-center gap-[20px] sm:gap-[25px] lg:gap-[30px] row-span-1">
      <SlideUpOnScroll className="w-[60px] h-[60px] sm:w-[70px] sm:h-[70px] lg:w-[80px] lg:h-[80px] bg-[#836FFF] flex justify-center items-center rounded-3xl">
        <img src={img} alt="" className="w-[35px] h-[35px] sm:w-[45px] sm:h-[45px] lg:w-[50px] lg:h-[50px]" />
      </SlideUpOnScroll>
      <SlideUpOnScroll className="text-[22px] sm:text-[26px] lg:text-3xl font-semibold text-[#F0F3FF] text-center">
        {title}
      </SlideUpOnScroll>
      <SlideUpOnScroll className="text-[16px] sm:text-[18px] lg:text-xl font-regular text-[#F0F3FF] text-center">
        {desc}
      </SlideUpOnScroll>
    </div>
  );
}
