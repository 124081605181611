import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import SlideUpOnScroll from "../../SlideUpOnScroll";
import odoo from "../../Assets/Landing/odoo.webp";
import whatsapp from "../../Assets/Landing/whatsapp.jpg";

export default function OurWorks() {
  const works = [
    {
      title: "Odoo Integration",
      description: "Seamless integration of Odoo ERP system with custom modules and workflows.",
      image: odoo
    },
    {
      title: "WhatsApp Business API",
      description: "Implementation of WhatsApp Business API for automated customer communication.",
      image: whatsapp
    }
  ];

  return (
    <div id="works" className="relative w-full text-[#F0F3FF] mb-[100px] z-30">
      <div className="w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-center w-full mb-[70px]">
          {/* Bottom Border */}
          <span
            className="absolute bottom-0 w-full h-[1px]"
            style={{
              background:
                "linear-gradient(to right, rgba(21, 245, 186, 0), rgba(21, 245, 186, 0.5), rgba(21, 245, 186, 0))",
              zIndex: 0,
            }}
          />
          <div className="flex justify-center items-center bg-[#1E193C] rounded-[30px] px-[50px] sm:px-[75px] lg:px-[100px] py-[8px] border border-solid border-[#15F5BA] border-opacity-50 z-10 transform translate-y-[50%]">
            <SlideUpOnScroll>
              <h1 className="text-[30px] sm:text-[35px] lg:text-[40px] font-semibold text-[#F0F3FF]">
                Our Works
              </h1>
            </SlideUpOnScroll>
          </div>
        </div>

        <Swiper
          modules={[Navigation, Scrollbar, A11y, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          breakpoints={{
            640: {
              spaceBetween: 40,
            },
            1024: {
              spaceBetween: 50,
            }
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          scrollbar={{ draggable: true }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
          className="w-full relative"
        >
          {works.map((work, index) => (
            <SwiperSlide key={index}>
              <div className="flex flex-col items-center space-y-4 sm:space-y-6">
                <img 
                  src={work.image} 
                  alt={work.title}
                  className="w-full h-[300px] sm:h-[350px] lg:h-[450px] object-cover rounded-lg shadow-lg"
                />
                <div className="text-center pb-[20px]"> 
                  <h2 className="text-xl sm:text-2xl font-semibold text-[#15F5BA] mb-2">
                    {work.title}
                  </h2>
                  <p className="text-base sm:text-lg text-[#F0F3FF]/80">
                    {work.description}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="swiper-button-next !text-[#15F5BA] after:!text-[16px] sm:after:!text-[18px] lg:after:!text-[20px]"></div>
          <div className="swiper-button-prev !text-[#15F5BA] after:!text-[16px] sm:after:!text-[18px] lg:after:!text-[20px]"></div>
        </Swiper>
      </div>
    </div>
  );
}
