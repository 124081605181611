import SlideUpOnScroll from "../../SlideUpOnScroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

export default function Contact() {
  return (
    <div id="contact" className="relative w-full text-[#F0F3FF] mb-[100px] z-30">
      <div className="w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-center w-full mb-[70px]">
          <span
            className="absolute bottom-0 w-full h-[1px]"
            style={{
              background:
                "linear-gradient(to right, rgba(21, 245, 186, 0), rgba(21, 245, 186, 0.5), rgba(21, 245, 186, 0))",
              zIndex: 0,
            }}
          />
          <div className="flex justify-center items-center bg-[#1E193C] rounded-[30px] px-[50px] sm:px-[75px] lg:px-[100px] py-[8px] border border-solid border-[#15F5BA] border-opacity-50 z-10 transform translate-y-[50%]">
            <SlideUpOnScroll>
              <h1 className="text-[30px] sm:text-[35px] lg:text-[40px] font-semibold text-[#F0F3FF]">
                Contact Us
              </h1>
            </SlideUpOnScroll>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-10 lg:gap-12">
          {/* Contact Information */}
          <SlideUpOnScroll className="space-y-6 sm:space-y-8">
            <div className="space-y-3 sm:space-y-4">
              <h2 className="text-2xl sm:text-3xl font-semibold text-[#15F5BA]">Get in Touch</h2>
              <p className="text-lg sm:text-xl text-[#F0F3FF]/80">
                Ready to take your business to the next level? Let's connect and discuss how we can build smart solutions tailored to your goals.
              </p>
            </div>

            <div className="space-y-4 sm:space-y-6">
              <div className="flex items-center space-x-3 sm:space-x-4">
                <FontAwesomeIcon icon={faEnvelope} className="text-xl sm:text-2xl text-[#15F5BA]" />
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold">Email</h3>
                  <p className="text-[#F0F3FF]/80">team@exocode.com</p>
                </div>
              </div>

              <div className="flex items-center space-x-3 sm:space-x-4">
                <FontAwesomeIcon icon={faPhone} className="text-xl sm:text-2xl text-[#15F5BA]" />
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold">Phone</h3>
                  <p className="text-[#F0F3FF]/80">+213676698858</p>
                </div>
              </div>

              <div className="flex items-center space-x-3 sm:space-x-4">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="text-xl sm:text-2xl text-[#15F5BA]" />
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold">Location</h3>
                  <p className="text-[#F0F3FF]/80">Algeria</p>
                </div>
              </div>

              {/* Social Media Links */}
              <div className="pt-4">
                <h3 className="text-2xl sm:text-3xl font-semibold text-[#F0F3FF] mb-3 sm:mb-4">Connect With Us</h3>
                <div className="flex space-x-4 sm:space-x-6">
                  <a 
                    href="https://linkedin.com/company/exocode" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-xl sm:text-2xl text-[#15F5BA] hover:text-[#0CA67D] transition-colors duration-300"
                  >
                    <svg className="w-6 h-6 sm:w-8 sm:h-8" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                    </svg>
                  </a>
                  <a 
                    href="https://wa.me/15551234567" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-xl sm:text-2xl text-[#15F5BA] hover:text-[#0CA67D] transition-colors duration-300"
                  >
                    <svg className="w-6 h-6 sm:w-8 sm:h-8" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"/>
                    </svg>
                  </a>
                  <a 
                    href="https://facebook.com/exocode" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-xl sm:text-2xl text-[#15F5BA] hover:text-[#0CA67D] transition-colors duration-300"
                  >
                    <svg className="w-6 h-6 sm:w-8 sm:h-8" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </SlideUpOnScroll>

          {/* Contact Form */}
          <SlideUpOnScroll className="bg-[#211951] p-6 sm:p-8 rounded-3xl">
            <form className="space-y-4 sm:space-y-6">
              <div>
                <label htmlFor="name" className="block text-base sm:text-lg font-medium mb-2">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full px-4 py-3 rounded-lg bg-[#1E193C] border border-[#15F5BA]/20 focus:border-[#15F5BA] focus:ring-1 focus:ring-[#15F5BA] outline-none transition-colors duration-300"
                  placeholder="Your name"
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-base sm:text-lg font-medium mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full px-4 py-3 rounded-lg bg-[#1E193C] border border-[#15F5BA]/20 focus:border-[#15F5BA] focus:ring-1 focus:ring-[#15F5BA] outline-none transition-colors duration-300"
                  placeholder="your@email.com"
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-base sm:text-lg font-medium mb-2">
                  Message
                </label>
                <textarea
                  id="message"
                  rows="4"
                  className="w-full px-4 py-3 rounded-lg bg-[#1E193C] border border-[#15F5BA]/20 focus:border-[#15F5BA] focus:ring-1 focus:ring-[#15F5BA] outline-none transition-colors duration-300"
                  placeholder="Your message"
                ></textarea>
              </div>

              <button
                type="submit"
                className="w-full bg-[#15F5BA] text-[#070226] text-base sm:text-lg font-semibold py-3 px-6 rounded-lg hover:bg-[#0CA67D] transition-colors duration-300"
              >
                Send Message
              </button>
            </form>
          </SlideUpOnScroll>
        </div>
      </div>
    </div>
  );
} 