import Service from "./Service";
import s1 from "../../Assets/Landing/s1.png";
import s2 from "../../Assets/Landing/s2.png";
import s3 from "../../Assets/Landing/s3.png";
import s4 from "../../Assets/Landing/s4.png";
import SlideUpOnScroll from "../../SlideUpOnScroll";

export default function OurServices() {
  const Services = [
    {
      img: s1,
      title: "Odoo Solutions for B2B",
      desc: "Tailored Odoo implementations to streamline your operations, from ERP to CRM and beyond. We customize Odoo to fit your unique business needs, helping you automate and scale with ease.",
    },
    {
      img: s2,
      title: "SaaS Product Development",
      desc: "We build secure, scalable SaaS applications to address real business challenges. Our solutions are crafted to grow with your company, delivering performance and efficiency.",
    },
    {
      img: s3,
      title: "AI-Powered Solutions",
      desc: "Leverage the power of AI to enhance your operations. We integrate AI into Odoo and SaaS platforms to automate tasks, provide predictive insights, and improve overall business performance.",
    },
    {
      img: s4,
      title: "Digital Transformation Consulting",
      desc: "Transform your business with expert consulting and tailored solutions. We help you navigate digital change, optimizing processes and implementing innovative technologies to stay competitive.",
    },
  ];

  return (
    <div id="services" className="relative w-full text-[#F0F3FF] z-30 mb-[100px]">
      <div className="w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-center w-full mb-[70px]">
          {/* Bottom Border */}
          <span
            className="absolute bottom-0 w-full h-[1px]"
            style={{
              background:
                "linear-gradient(to right, rgba(21, 245, 186, 0), rgba(21, 245, 186, 0.5), rgba(21, 245, 186, 0))",
              zIndex: 0,
            }}
          />

          {/* Centered Title Box */}
          <div className="flex justify-center items-center bg-[#1E193C] rounded-[30px] px-[50px] sm:px-[75px] lg:px-[100px] py-[8px] border border-solid border-[#15F5BA] border-opacity-50 z-10 transform translate-y-[50%]">
            <SlideUpOnScroll>
              <h1 className="text-[30px] sm:text-[35px] lg:text-[40px] font-semibold text-[#F0F3FF]">
                Our Services
              </h1>
            </SlideUpOnScroll>
          </div>
        </div>

        <div className="mb-[50px] sm:mb-[75px] lg:mb-[100px]">
          <SlideUpOnScroll>
            <p className="text-[16px] sm:text-[18px] lg:text-[20px] font-regular text-[#F0F3FF] text-center">
              Our expert team provides tailored digital solutions, from SaaS products and AI-driven tools to custom Odoo systems and web applications. Here's a sample of what we offer — reach out to learn more.
            </p>
          </SlideUpOnScroll>
        </div>

        <div className="relative grid grid-cols-1 md:grid-cols-2 gap-x-[50px] sm:gap-x-[100px] lg:gap-x-[200px] gap-y-[50px] sm:gap-y-[75px] lg:gap-y-[100px]">
          {/* Services */}
          {Services.slice(0, 4).map((service) => (
            <Service
              key={service.title}
              img={service.img}
              title={service.title}
              desc={service.desc}
            />
          ))}

          {/* Center Gradient Divider */}
          <div
            className="hidden md:block absolute top-0 bottom-0 left-1/2 transform -translate-x-1/2 w-[10px] h-full"
            style={{
              background:
                "linear-gradient(to bottom, rgba(7, 2, 38, 0.2) 0%, #836FFF 50%, rgba(7, 2, 38, 0.2) 100%)",
            }}
          />
        </div>
      </div>
    </div>
  );
}
