import Quest from "./Quest";
import SlideUpOnScroll from "../../SlideUpOnScroll";
import { useState } from "react";

const whyExoCodeData = [
  {
    title: "Innovation",
    desc: "ExoCode leads in innovation, leveraging cutting-edge technologies to deliver state-of-the-art digital solutions that push the boundaries of what's possible.",
  },
  {
    title: "Expertise Across Technologies",
    desc: "Our team is proficient in modern technologies like React, Next.js, and cloud computing, ensuring robust and scalable solutions tailored to your business needs.",
  },
  {
    title: "Customer-Centric Approach",
    desc: "We prioritize understanding your goals and challenges, providing bespoke solutions that are aligned with your business vision and ensuring long-term success.",
  },
  {
    title: "Scalable Solutions",
    desc: "Whether you're a startup or an established enterprise, ExoCode designs scalable solutions that can grow alongside your business, ensuring future-proof applications.",
  },
  {
    title: "Transparent Communication",
    desc: "We keep you in the loop throughout the project lifecycle, ensuring clear communication, timely updates, and a seamless collaboration experience.",
  },
  {
    title: "Security First",
    desc: "Security is embedded in every solution we provide. We implement the best practices to safeguard your data, ensuring your applications remain protected from threats.",
  },
];

export default function WhyExoCodeSection() {
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle the FAQ item by index
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="relative w-full text-[#F0F3FF] mb-[100px]">
      <div className="w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-center w-full mb-[70px]">
          <span
            className="absolute bottom-0 w-full h-[1px]"
            style={{
              background:
                "linear-gradient(to right, rgba(21, 245, 186, 0), rgba(21, 245, 186, 0.5), rgba(21, 245, 186, 0))",
              zIndex: 0,
            }}
          />
          <div className="flex justify-center items-center bg-[#1E193C] rounded-[30px] px-[50px] sm:px-[75px] lg:px-[100px] py-[8px] border border-solid border-[#15F5BA] border-opacity-50 z-10 transform translate-y-[50%]">
            <SlideUpOnScroll>
              <h1 className="text-[30px] sm:text-[35px] lg:text-[40px] font-semibold text-[#F0F3FF]">
                Why ExoCode
              </h1>
            </SlideUpOnScroll>
          </div>
        </div>

        <div className="mb-[50px] sm:mb-[75px] lg:mb-[100px]">
          <SlideUpOnScroll>
            <p className="text-[16px] sm:text-[18px] lg:text-[20px] font-regular text-[#F0F3FF] text-center">
              ExoCode delivers digital solutions with innovation and expertise.
              Discover what makes us a trusted partner for businesses seeking to
              go beyond the ordinary.
            </p>
          </SlideUpOnScroll>
        </div>

        <div className="w-full flex flex-col gap-4 sm:gap-5">
          {whyExoCodeData.map((item, index) => (
            <Quest
              key={index}
              title={item.title}
              desc={item.desc}
              isOpen={openIndex === index}
              onToggle={() => handleToggle(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
