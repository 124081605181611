import SlideUpOnScroll from "../../SlideUpOnScroll";
import logo from "../../Assets/Landing/ExoCode.png";

export default function Footer() {
  return (
    <div className="relative w-full flex flex-col items-center text-[#F0F3FF] py-[50px] z-30">
      <div className="w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative w-full mb-[70px]">
          <span
            className="absolute bottom-0 w-full h-[1px]"
            style={{
              background:
                "linear-gradient(to right, rgba(21, 245, 186, 0), rgba(21, 245, 186, 0.5), rgba(21, 245, 186, 0))",
              zIndex: 0,
            }}
          />
        </div>
        <div className="flex flex-col items-center space-y-8">
          {/* Logo */}
          <SlideUpOnScroll>
            <img src={logo} alt="ExoCode Logo" className="h-[80px] w-auto" />
          </SlideUpOnScroll>

          {/* Message */}
          <SlideUpOnScroll>
            <p className="text-center text-base sm:text-lg lg:text-xl max-w-2xl text-[#F0F3FF]/80">
              Empowering businesses with innovative digital solutions. Let's build the future together.
            </p>
          </SlideUpOnScroll>

          {/* Copyright */}
          <SlideUpOnScroll>
            <div className="flex items-center space-x-2 text-sm sm:text-base text-[#F0F3FF]/60">
              <span>© {new Date().getFullYear()}</span>
              <span className="text-[#15F5BA]">ExoCode</span>
              <span>All rights reserved.</span>
            </div>
          </SlideUpOnScroll>
        </div>
      </div>
    </div>
  );
} 