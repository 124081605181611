import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import SlideUpOnScroll from "../../SlideUpOnScroll";

export default function Quest({ title, desc, isOpen, onToggle }) {
  return (
    <SlideUpOnScroll>
      <div
        className="relative px-10 py-[30px] w-full rounded-3xl bg-[#211951] text-[#F0F3FF] cursor-pointer transition-all duration-300"
        onClick={onToggle} // Toggle the open/close state on click
      >
        <div className="flex items-center justify-between">
          <h3 className="text-3xl font-semibold">{title}</h3>
          {/* Toggle arrow icon */}
          <FontAwesomeIcon
            icon={isOpen ? faChevronUp : faChevronDown}
            className="text-2xl transition-transform duration-300 ease-in-out"
            style={{ transform: isOpen ? 'rotate(0deg)' : 'rotate(0deg)' }}
          />
        </div>

        {/* Answer section with smooth transition and left alignment */}
        <div
          className={`overflow-hidden transition-all duration-500 ease-in-out ${
            isOpen ? "max-h-[500px] opacity-100 pt-4" : "max-h-0 opacity-0"
          }`}
        >
          <p className="text-xl text-left transform transition-transform duration-500 ease-in-out">
            {desc}
          </p>
        </div>
      </div>
    </SlideUpOnScroll>
  );
}
