import React, { useEffect, useState, useRef } from "react";

const SlideUpOnScroll = ({ children, className }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  const checkVisibility = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Trigger slide-up only when entering viewport from the bottom
      if (rect.top < windowHeight && rect.top > 0) {
        setIsVisible(true); // Make visible with animation
      } else if (rect.top >= windowHeight) {
        setIsVisible(false); // Hide element when it goes back down
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);
    checkVisibility(); // Check on initial render

    return () => {
      window.removeEventListener("scroll", checkVisibility);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`${className} transition-transform transition-opacity duration-700 ${
        isVisible
          ? "opacity-100 translate-y-0" // Element slides up and fades in
          : "opacity-0 translate-y-5" // Element slides down and fades out
      }`}
    >
      {children}
    </div>
  );
};

export default SlideUpOnScroll;
