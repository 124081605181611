import { useState } from "react";
import logo from "../Assets/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = (e, targetId) => {
    e.preventDefault();
    if (targetId === 'top') {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
        duration: 500
      });
      return;
    }

    const element = document.getElementById(targetId);
    if (element) {
      const navbarHeight = 100; // Height of the fixed navbar
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
        duration: 500
      });
    }
    setIsMenuOpen(false); // Close menu after clicking a link
  };

  return (
    <div className="fixed top-0 w-full h-[100px] bg-[#070226]/50 backdrop-blur-lg z-50">
      <div className="w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
        <div className="flex justify-between items-center h-full">
          <img src={logo} alt="" className="h-[60px]" />
          
          {/* Desktop Navigation */}
          <div className="hidden md:flex text-[20px] font-semibold text-[#F0F3FF] justify-between items-center space-x-8">
            <button 
              onClick={(e) => handleScroll(e, 'top')}
              className="hover:text-[#15F5BA] transition-colors duration-300"
              aria-label="Scroll to top"
            >
              Home
            </button>
            <a 
              href="#services" 
              onClick={(e) => handleScroll(e, 'services')}
              className="hover:text-[#15F5BA] transition-colors duration-300"
            >
              Services
            </a>
            <a 
              href="#works" 
              onClick={(e) => handleScroll(e, 'works')}
              className="hover:text-[#15F5BA] transition-colors duration-300"
            >
              Our Works
            </a>
            <a 
              href="#contact" 
              onClick={(e) => handleScroll(e, 'contact')}
              className="hover:text-[#15F5BA] transition-colors duration-300"
            >
              Contact
            </a>
          </div>

          {/* Mobile Menu Button */}
          <button 
            className="md:hidden text-[#F0F3FF] text-2xl"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
          </button>

          {/* Mobile Navigation Menu */}
          <div 
            className={`absolute top-[100px] left-0 w-full bg-[#070226]/50 backdrop-blur-lg transition-all duration-300 ease-in-out z-[60] ${
              isMenuOpen ? 'translate-y-0 opacity-100 visible' : '-translate-y-full opacity-0 invisible'
            } md:hidden`}
          >
            <div className="w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex flex-col py-4">
                <button 
                  onClick={(e) => handleScroll(e, 'top')}
                  className="text-[18px] font-semibold text-[#F0F3FF] hover:text-[#15F5BA] transition-colors duration-300 py-3 border-b border-[#15F5BA]/20"
                  aria-label="Scroll to top"
                >
                  Home
                </button>
                <a 
                  href="#services" 
                  onClick={(e) => handleScroll(e, 'services')}
                  className="text-[18px] font-semibold text-[#F0F3FF] hover:text-[#15F5BA] transition-colors duration-300 py-3 border-b border-[#15F5BA]/20"
                >
                  Services
                </a>
                <a 
                  href="#works" 
                  onClick={(e) => handleScroll(e, 'works')}
                  className="text-[18px] font-semibold text-[#F0F3FF] hover:text-[#15F5BA] transition-colors duration-300 py-3 border-b border-[#15F5BA]/20"
                >
                  Our Works
                </a>
                <a 
                  href="#contact" 
                  onClick={(e) => handleScroll(e, 'contact')}
                  className="text-[18px] font-semibold text-[#F0F3FF] hover:text-[#15F5BA] transition-colors duration-300 py-3"
                >
                  Contact
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "1px",
          background:
            "linear-gradient(to right, rgba(21, 245, 186, 0), rgba(21, 245, 186, 0.5), rgba(21, 245, 186, 0))",
        }}
      />
    </div>
  );
}
